import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Link from 'gatsby-link';

import linkResolver from 'utils/linkResolver';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  min-height: 500px;
  position: relative;
  overflow: hidden;
`;

export const Overlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  transition: background 0.3s ease-in-out;
`;

export const ImageLink = styled(Link)`
  text-decoration: none;
  &:hover {
    .overlay {
      background: #5a6364;
    }
  }
`;

function ProjectGrid(props) {
  const { items } = props;
  console.log(items);
  return (
    <Box mt={10}>
      <Box mb={10}>
        <Typography variant="h2" align="center">
          OTHER RELEVANT SITES
        </Typography>
      </Box>
      {items && (
        <div className="spaced-grid-wrapper">
          <Grid container spacing={4}>
            {items.map((item, key) => {
              return (
                <Grid item xs={12} md={6} key={key}>
                  <Background
                    image={item.project.document.data.featured_image.url}
                  >
                    <ImageLink to={linkResolver(item.project)}>
                      <Overlay className="overlay" />
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="space-around"
                        height="100%"
                        position="relative"
                        zIndex="2"
                      >
                        <Box />
                        <Box>
                          <Typography
                            variant="h3"
                            color="textSecondary"
                            align="center"
                          >
                            {item.project.document.data.project_title.text}
                          </Typography>
                          <Typography
                            variant="h3"
                            color="textSecondary"
                            align="center"
                            gutterBottom
                          >
                            {item.project.document.data.project_location.text}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                          >
                            {
                              item.project.document.data.project_status.document
                                .data.status
                            }
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component="span"
                            color="textSecondary"
                            className="standard-text-link"
                          >
                            VIEW
                          </Typography>
                        </Box>
                      </Box>
                    </ImageLink>
                  </Background>
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
    </Box>
  );
}

ProjectGrid.propTypes = {
  items: PropTypes.array,
};

function RelatedProjects(props) {
  const { items } = props;
  return <ProjectGrid items={items} />;
}

RelatedProjects.propTypes = {
  items: PropTypes.array,
};

export default RelatedProjects;
