import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Link from 'gatsby-link';

import linkResolver from 'utils/linkResolver';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  min-height: 500px;
  position: relative;
  overflow: hidden;
`;

export const Overlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  transition: background 0.3s ease-in-out;
`;

export const ImageLink = styled(Link)`
  text-decoration: none;
  &:hover {
    .overlay {
      background: #5a6364;
    }
  }
`;

const counties = [
  'Derbyshire',
  'Oxfordshire',
  'Staffordshire',
  'Warwickshire',
  'Worcestershire',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ProjectGrid(props) {
  const { data, items } = props;
  console.log(data);
  const [projectsName, setProjectsName] = useState([]);
  const [projectsStatus, setProjectsStatus] = useState([]);
  const handleChange = event => {
    setProjectsName(event.target.value);
  };
  const handleChangeStatus = event => {
    setProjectsStatus(event.target.value);
  };

  let dataarray = data.allPrismicProject.nodes.filter(item => {
    var includeItem = true;
    if (projectsName.length > 0) {
      projectsName.includes(item.data.project_location.text)
        ? null
        : (includeItem = false);
    }
    if (projectsStatus.length > 0) {
      projectsStatus.includes(item.data.project_status.document.data.status)
        ? null
        : (includeItem = false);
    }
    return includeItem;
  });

  const countItemsOfThisStatus = status => {
    var count = 0;
    dataarray.map(item => {
      if (status == item.data.project_status.document.data.status) {
        count++;
      }
    });
    if (count > 0) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <Box mb={8}>
        <div className="spaced-grid-wrapper">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box mb={5}>
                <Typography component="h3" variant="h3">
                  Filter our projects
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl>
                <InputLabel
                  id="mutiple-checkbox-label"
                  className="text-primary"
                >
                  Select Counties
                </InputLabel>
                <Select
                  labelId="mutiple-checkbox-label"
                  id="mutiple-checkbox"
                  multiple
                  value={projectsName}
                  onChange={handleChange}
                  input={<Input />}
                  renderValue={selected => selected.join(', ')}
                >
                  {counties.map(name => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={projectsName.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl>
                <InputLabel
                  id="mutiple-checkbox-label"
                  className="text-primary"
                >
                  Select Project Status
                </InputLabel>
                <Select
                  labelId="mutiple-checkbox-label"
                  id="mutiple-checkbox"
                  multiple
                  value={projectsStatus}
                  onChange={handleChangeStatus}
                  input={<Input />}
                  renderValue={selected => selected.join(', ')}
                >
                  {data.allPrismicProjectStatus.nodes.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.data.status}>
                        <Checkbox
                          checked={
                            projectsStatus.indexOf(item.data.status) > -1
                          }
                        />
                        <ListItemText primary={item.data.status} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </Box>
      <div className="spaced-grid-wrapper">
        <Grid container spacing={4}>
          {dataarray.length == 0 && (
            <Grid item xs={12}>
              NO RESULTS
            </Grid>
          )}
          {items.map((statusitem, key) => {
            if (
              countItemsOfThisStatus(statusitem.status.document.data.status)
            ) {
              return (
                <React.Fragment key={key}>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Typography component="p" variant="body1">
                        {statusitem.status.document.data.status}
                      </Typography>
                    </Box>
                  </Grid>
                  {dataarray
                    .sort((a, b) =>
                      a.data.project_title.text > b.data.project_title.text
                        ? 1
                        : -1
                    )
                    .map((item, key) => {
                      if (
                        statusitem.status.document.data.status ==
                        item.data.project_status.document.data.status
                      ) {
                        return (
                          <React.Fragment key={key}>
                            <Grid item xs={12} md={6}>
                              <Background image={item.data.featured_image.url}>
                                <ImageLink to={linkResolver(item)}>
                                  <Overlay className="overlay" />
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="space-around"
                                    height="100%"
                                    position="relative"
                                    zIndex="2"
                                  >
                                    <Box />
                                    <Box>
                                      <Typography
                                        variant="h3"
                                        color="textSecondary"
                                        align="center"
                                      >
                                        {item.data.project_title.text}
                                      </Typography>
                                      <Typography
                                        variant="h3"
                                        color="textSecondary"
                                        align="center"
                                        gutterBottom
                                      >
                                        {item.data.project_location.text}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        align="center"
                                      >
                                        {
                                          item.data.project_status.document.data
                                            .status
                                        }
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography
                                        component="span"
                                        color="textSecondary"
                                        className="standard-text-link"
                                      >
                                        VIEW
                                      </Typography>
                                    </Box>
                                  </Box>
                                </ImageLink>
                              </Background>
                            </Grid>
                          </React.Fragment>
                        );
                      }
                    })}
                </React.Fragment>
              );
            }
          })}
        </Grid>
      </div>
    </React.Fragment>
  );
}

ProjectGrid.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

const Query = graphql`
  {
    allPrismicProject {
      nodes {
        data {
          featured_image {
            alt
            url
          }
          project_title {
            html
            raw
            text
          }
          project_location {
            html
            raw
            text
          }
          project_status {
            document {
              ... on PrismicProjectStatus {
                data {
                  status
                }
              }
            }
          }
        }
        type
        uid
      }
    }
    allPrismicProjectStatus {
      nodes {
        data {
          status
        }
      }
    }
  }
`;

function AllProjects(props) {
  const { items } = props;
  return (
    <StaticQuery
      query={`${Query}`}
      render={data => <ProjectGrid data={data} items={items} />}
    />
  );
}

AllProjects.propTypes = {
  items: PropTypes.array.isRequired,
};

export default AllProjects;
