import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { RichText } from 'prismic-reactjs';

function TitleContentColumn(props) {
  const { data } = props;
  return (
    <Box component={Grid} container justifyContent="space-between">
      <Grid item xs={10} md={4}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box mb={{ xs: 4, md: 0 }}>
            <Typography variant="h3" component="h2">
              {data.title.text}
            </Typography>
          </Box>
          <Typography component="span" className="caption-body">
            <RichText render={data.caption.raw} />
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography component="span" variant="body1">
          <RichText render={data.content.raw} />
        </Typography>
      </Grid>
    </Box>
  );
}

TitleContentColumn.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TitleContentColumn;
