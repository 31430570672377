import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  min-height: calc(80vh);
  position: relative;
  overflow: hidden;
  display: flex;
`;

function QuoteImageBlock(props) {
  const { data } = props;
  return (
    <Container maxWidth="lg">
      <Background image={data.image.url}>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Box
            width={{ xs: '100%', md: '50%' }}
            py={{ xs: 3, md: 10 }}
            pr={{ xs: 3, md: 10 }}
            pl={{ xs: 3, md: 0 }}
          >
            <Box
              bgcolor="secondary.main"
              height="100%"
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Typography
                component="h3"
                variant="h2"
                className="text-uppercase"
                align="center"
              >
                {data.title.text}
              </Typography>
              <Typography component="div" variant="body1" align="center">
                <RichText render={data.quote.raw} />
              </Typography>
              <div>
                <Typography
                  component="p"
                  variant="body1"
                  align="center"
                  className="text-uppercase"
                >
                  {data.author.text}
                </Typography>
                <Typography component="p" variant="body1" align="center">
                  {data.job_title.text}
                </Typography>
              </div>
              <Box display="flex" justifyContent="center">
                <img
                  src={data.brand.url}
                  alt={data.author.text}
                  className="brand-logo"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Background>
    </Container>
  );
}

QuoteImageBlock.propTypes = {
  data: PropTypes.object.isRequired,
};

export default QuoteImageBlock;
