import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from 'gatsby-link';
import IconSml from 'images/icon-sml.svg';

import linkResolver from 'helpers/linkResolver';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
  overflow: hidden;
`;

function FullImage(props) {
  const { data } = props;
  return (
    <Background image={data.image.url}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        height="100%"
      >
        <Box pt={5}>
          <IconSml className="icon-sml visibility-hidden" />
        </Box>
        <Box>
          <Typography
            variant="h1"
            component="h2"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            {data.title.text}
          </Typography>
          {data.caption && (
            <Typography
              component="p"
              variant="body2"
              color="textSecondary"
              align="center"
            >
              {data.caption.text}
            </Typography>
          )}
        </Box>
        <Box pb={5}>
          <IconSml className="icon-sml" />
        </Box>
        <Box mb={5}>
          {data.link.url && !data.link.uid && (
            <a
              href={data.link.url}
              target="_blank"
              rel="noreferrer"
              className="standard-text-link"
            >
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
                className="standard-text-link-text"
              >
                VIEW
              </Typography>
            </a>
          )}
          {data.link.uid && (
            <Link to={linkResolver(data.link)} className="standard-text-link">
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
                className="standard-text-link-text"
              >
                VIEW
              </Typography>
            </Link>
          )}
        </Box>
      </Box>
    </Background>
  );
}

FullImage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FullImage;
