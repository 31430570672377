import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TitleText(props) {
  const { data } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={4}>
        <Typography component="h2" variant="h2" align="center">
          {data.title.text}
        </Typography>
      </Box>
      {data.content && data.content.text && (
        <Box width={{ xs: '100%', md: '50%' }}>
          <Typography component="p" variant="body1" align="center">
            {data.content.text}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

TitleText.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TitleText;
